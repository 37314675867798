/**
 * CustomPhoneNumber Component
 * 
 * A reusable form field component for phone number input. It is used throughout the application wherever a phone number field is needed.
 * 
 * Features:
 * - Allows for phone number input with automatic country code selection and flag display.
 * - Can be customized to include a required field indicator.
 * - Handles validation for phone numbers (if required), ensuring the phone number is valid and includes the area code.
 * - Default country is set to Australia (`au`).
 * 
 * Props:
 * - `isRequired` (optional): Boolean that determines if the phone number field is required (default: false).
 * - `name` (optional): The name of the field (default: 'phone').
 * - `label` (optional): The label for the input field (default: 'Phone Number').
 * 
 * Validation:
 * - If `isRequired` is set to `true`, the phone number must be entered and must have at least 10 digits.
 * - If the phone number is invalid or too short, an error message will be shown.
 * 
 * Usage Example:
 * <CustomPhoneNumber isRequired={true} name="userPhone" label="User Phone Number" />
 * 
 */
import React, { useState } from "react";
import { Form } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface PhoneNumberType {
    isRequired?: boolean;
    name?: string;
    label?: any;
    isToolTip?: boolean;
}

const CustomPhoneNumber = ({ isRequired = false, isToolTip=false, name = "phone", label = "Phone Number" }: PhoneNumberType) => {
    const [phone, setPhone] = useState<string>("");

    const validatePhoneNumber = (_: any, value: string) => {
        if (isRequired) {
            if (!value) {
                return Promise.reject(new Error("Phone number is required!"));
            } else if (value.replace(/\D/g, "").length < 10) {
                return Promise.reject(new Error("Invalid phone number!"));
            }
            return Promise.resolve();
        } else {
            return Promise.resolve();
        }
    };

    return (
        <div style={{ maxWidth: '100%' }}>
            <Form.Item
                name={name}
                label={
                    isToolTip ? 
                    label 
                    :<div>
                        {isRequired && <span style={{ color: "#ff4d4f", fontSize: '14px' }}>*</span>} {label} (Including area code)
                    </div>
                }
                rules={[
                    {
                        validator: validatePhoneNumber,
                    },
                ]}
            >
                <PhoneInput
                    country="au" // Default country
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    inputStyle={{
                        width: "100%",
                        height: "49px",
                        borderRadius: "4px",
                        border: "1px solid #d9d9d9",
                        paddingLeft: "48px",
                    }}
                    buttonStyle={{
                        borderRadius: "4px",
                        border: "1px solid #d9d9d9",
                    }}
                    enableSearch={true} // Enable country search
                />
            </Form.Item>
        </div>
    );
};

export default CustomPhoneNumber;
