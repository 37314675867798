import React from 'react';
// import { Navigate } from 'react-router-dom';
// Layout Types
import { PrivateLayout, WebLayout } from '../layouts';
// import { AddTask } from '@components/ToDo/AddTask';
import Register from '@components/RegisterForm/Register';
import { PaymentForm } from '@components/RegisterForm/PaymentForm';
import PaymentSuccessful from '../pages/PaymentSuccess/PaymentSuccessful';
import ContactUS from '../pages/ContactUS/ContactUS';
import ReferUS from '../pages/ReferUS/ReferUs';
// import Home from '../pages/Home/Home';
import { Businesspage } from '../pages/Businesspage/Businesspage';
import ReferrerSignUp from '../pages/ReferUS/ReferrerSignUp';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import ReferrerMarketing from '../pages/ReferrerMarketingPage/ReferrerMarketing';
import SendReferralMarketting from '../pages/ReferrerMarketingPage/SendReferralMarketting';
import { MarketingLandingPage } from '../pages/ReferrerMarketingPage/MarketingLandingPage';
import HomeWrapper from '../pages/Home/HomeWrapper';
import ReferReferUsMarkettingPage from '../pages/Refer-ReferUs/ReferReferUsMarkettingPage';
import ReferReferUsRegister from '../pages/Refer-ReferUs/ReferReferUsRegister';
import ReferUsNetwork from '../pages/ReferUsNetwork/ReferUsNetwork';

// Route Views
const AuthRoutes = [
    {
        path: '/',
        exact: true,
        layout: WebLayout,
        component: HomeWrapper,
    },
    {
        path: '/business-details/:businessName/:businessCode',
        exact: true,
        layout: WebLayout,
        component: Businesspage,
    },
    {
        path: '/business-details/register-referrer/:businessName/:businessCode',
        exact: true,
        layout: PrivateLayout,
        component: ReferrerSignUp,
    },
    {
        path: '/business-details/:businessName/:businessCode',
        exact: true,
        layout: WebLayout,
        component: ReferrerMarketing,
    },
    {
        path: '/business-details/:businessName/:businessCode/refer/:referCode',
        exact: true,
        layout: WebLayout,
        component: MarketingLandingPage,
    },
    {
        path: '/business-details/send-referral/:businessName/:businessCode/sender/:senderCode',
        exact: true,
        layout: PrivateLayout,
        component: SendReferralMarketting,
    },
    // *********** landing page for refer refer-us marketting ***************** //
    {
        path: '/refer-us/:businessCode',
        exact: true,
        layout: WebLayout,
        component: ReferReferUsMarkettingPage,
    },
    // ********* register refer refer us business ************ //
    {
        path: '/register/refer-refer-us/:businessCode',
        exact: true,
        layout: PrivateLayout,
        component: ReferReferUsRegister,
    },
    {
        path: '/payment-success',
        exact: true,
        layout: PrivateLayout,
        component: PaymentSuccessful,
    },
    // {
    //     path: '/login',
    //     exact: true,
    //     layout: PrivateLayout,
    //     component: AddTask,
    // },
    {
        path: '/register',
        exact: true,
        layout: PrivateLayout,
        component: Register,
    },
    {
        path: '/contact-us',
        exact: true,
        layout: PrivateLayout,
        component: ContactUS,
    },
    {
        path: '/refer-us',
        exact: true,
        layout: PrivateLayout,
        component: ReferUS,
    },
    {
        path: '/refer-us-network',
        exact: true,
        layout: WebLayout,
        component: ReferUsNetwork,
    },
    {
        path: '/payment',
        exact: true,
        layout: PrivateLayout,
        component: PaymentForm,
    },
    {
        path: '/payment-successful',
        exact: true,
        layout: PrivateLayout,
        component: PaymentSuccessful,
    },
    {
        path: '/terms-and-conditions',
        exact: true,
        layout: WebLayout,
        component: TermsAndConditions,
    },
    {
        path: '/privacy-policy',
        exact: true,
        layout: WebLayout,
        component: PrivacyPolicy,
    },
];

export default AuthRoutes;
