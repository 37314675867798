import React from 'react';
import { Tooltip } from "antd";
import { QuestionCircleFilled } from '@ant-design/icons';
import './index.css'

type ToolTipType = {
    label?: any; // Use lowercase 'string' for TypeScript
    title?: string; // Use lowercase 'string' for TypeScript
    visible: Record<string, boolean>; // Record type for key-value pairs
    setVisible: React.Dispatch<React.SetStateAction<Record<string, boolean>>>; // Specific type for setVisible
    titleKey: string; // Use string for key
}

const LabelWithToolTip: React.FC<ToolTipType> = ({ title, label, visible, setVisible, titleKey }) => {

    const handleTooltipDismiss = () => {
        setVisible((prev) => ({
            ...prev,
            [titleKey]: false // Set the specific key to false
        }));
    };

    const handleTooltipShow = () => {
        setVisible((prev) => ({
            ...prev,
            [titleKey]: true // Set the specific key to true
        }));
    };

    return (
        <span>
            {label}&nbsp;
            {visible?.[titleKey] ?
                <Tooltip
                    title={title}
                    color={'#F47522'}
                    open={visible[titleKey]} // Control visibility of the tooltip
                >
                </Tooltip>
                : ""}

            <span
                onMouseEnter={handleTooltipShow}
                onMouseLeave={handleTooltipDismiss}
                onClick={handleTooltipDismiss} // Dismiss tooltip on click
                style={{ cursor: 'pointer', color: '#1890ff' }}
            >
                <QuestionCircleFilled style={{ fontSize: '16px', color: 'rgb(244 117 34 / 71%)' }} />
            </span>
        </span>
    );
}

export default LabelWithToolTip;