import React from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';
import avtar from "../../../images/user/user-01.png"

import { Avatar } from 'antd';

const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  message.info('Click on left button.');
  console.log('click left button', e);
};

const handleMenuClick: MenuProps['onClick'] = (e) => {
  message.info('Click on menu item.');
  console.log('click', e);
};

const items: MenuProps['items'] = [
  {
    label: 'Profile',
    key: '1',

  },
  {
    label: 'Logout',
    key: '2',

  },

];

const menuProps = {
  items,
  onClick: handleMenuClick,
};

const DropdownUser = () => {


  return (
    <>
      <Dropdown menu={menuProps}>
        <button className='btn'>
          <Avatar src={<img src={avtar} alt="avatar" />} />
        </button>
      </Dropdown>
    </>
  );
};

export default DropdownUser;
