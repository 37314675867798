import { capitalizeFirstLetter, formatAndCapitalizeString, truncateText } from '@utils/common/common';
import noImage from '../../images/business/noDoc.png'
import React from 'react';
import { useSelector } from 'react-redux';

function BusinessListing({data, index, isBusiness=false, isReferUsNetwork=false}: any) {
  let businessData = data?.BusinessDetails;
  const userData = useSelector((state: any) => state?.Auth?.user?.user || state?.Auth?.user?.data);
  let refCode = userData?.userRole === 3 ? userData?.referrerCode : userData?.businessCode;
  let url = businessData?.url;
  const link = (!isReferUsNetwork || data?.businessIRefer) ? `${url}/refer/${refCode}` : url;
  return (
    <div key={index}>
      <div className="feature-card">
        <div className="card-header">
        <div>
            {businessData?.category ? <div className="card-feature">
                <span className='feature-tag'>{(formatAndCapitalizeString(businessData?.category))}</span>
            </div> :""}
            <h2 className="card-title">{businessData?.businessName}</h2>
            <div className="card-subtitle">{truncateText(businessData?.description, 120)}</div>
        </div>
        <img src={businessData?.logo ?? noImage} title={businessData?.logo ? "Logo" : "No Logo found"} alt={"Logo"} className="avatar" />
        </div>
        <div className='footer-wrapper'>
            <div className='card-service'>
            <div className="services-list text-break">
              {isBusiness ? businessData?.services && businessData?.services?.split(',')?.map((item: any, index: number) => {
                return <div key={index}>{capitalizeFirstLetter(item)}</div>
              })
                : businessData?.services && businessData?.services?.split(', ')?.map((item: any, index: number) => {
                  return <div key={index}>{capitalizeFirstLetter(item)}</div>
                })}
            </div>
            </div>
            <div className="footer">
                <button onClick={()=> window.open(link, '_blank', 'noopener,noreferrer')} className="link-button">View More</button>
            </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessListing;