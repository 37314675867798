import React, { useEffect, useState } from 'react';
import Header from '@components/Comman/Header';
import { Button, Modal, Row, Col, Input, Select, Empty, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import './index.css'
import CustomCard from './CustomCard';
import BusinessListing from './FeatureCard';
import { getReferUsNetworkBusinesses } from '../../actions/business';
import { businessCategories } from '@constants/map';
import CardPagination from '@components/Comman/CardPagination';
// import { appendRegisterReferrer } from '@utils/common/common';

interface DataType {
    key: string;
    firstName: string;
    lastName: string;
    email: string;
    businessCode: string;
    isRegistered: boolean;
}

interface ReferrerType {
    adminId?: number
}

interface PayloadType {
    limit: number;
    page: number;
    role: number;
    admin_id?: number;
};

const ReferUsNetwork = ({ adminId }: ReferrerType) => {
    const [data, setData] = useState<DataType[]>([]);
    const host = window?.location?.origin;
    const [pagination, setPagination] = useState({ totalItems: 0 });
    const [showConfirm, setShowConfirm] = useState(false)
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const userData = useSelector((state: any) => state?.Auth?.user);
    const businessList = useSelector((state: any) => state?.Business?.businessDetails);
    const userRoleId = userData?.data?.roleId;
    const { isLoading } = useSelector((state: any) => state?.General);
    const [searchValues, setSearchValues] = useState({ businessName: '', category: '', postcode: '', otherCategory : '' });



    const getBusinessIReferData =(payloadD: any)=> {
        let payload = { limit: pageSize, page: page, ...payloadD };

        if (userRoleId === 1) {
            payload = { ...payload, admin_id: adminId }
        }
        dispatch(
            getReferUsNetworkBusinesses(payload, (resp: any) => {
                if (resp?.status === 200) {
                    setData(resp?.data);
                    setPagination(resp?.pagination);
                }
            })
        );
    }

    useEffect(() => {
        let payload: PayloadType = { limit: pageSize, page, role: userRoleId };
        getBusinessIReferData(payload);
    }, [page, pageSize, dispatch]);

    const handleTableChange = (pagination: any) => {
        setPage(pagination);
    };

    // const handleReferrerClick =(record: any)=> {
    //     let registerReferrerUrl:string = ''
    //     let busCode:any=record?.businessCode;
    //     let busName:any=record?.BusinessDetails?.businessName?.replace(/ /g, '_')
    //     let referrerCode:any=record?.senderCode;
    //     let businessData=record;

    //     if (referrerCode) {
    //         registerReferrerUrl = `${host}/business-details/register-referrer/${busName}/${busCode}?referrerCode=${referrerCode.toUpperCase()}`;
    //     } else {
    //         registerReferrerUrl = appendRegisterReferrer(record?.BusinessDetails?.url);
    //     }

    //     window.open(registerReferrerUrl, '_blank', 'noopener,noreferrer');
    // }

    const tableComponent = (
        <>
            <div className="responsivetable"> 
                <div className='item-wrapper'>
                    {data?.map((item:any, index:number) => {
                        return (
                            <BusinessListing isReferUsNetwork={true} data={item} key={index} />
                        )
                    })}
                </div>
                {data?.length === 0 && <div style={{ textAlign: 'center', height: '60vh'}}><Empty /></div>}
            </div>

        </>
    );

    const handleSearch =()=> {
        let values = {...searchValues};
        if(values.category == 'other'){
           values.category = values.otherCategory;
        }
       
        delete values.otherCategory;
        getBusinessIReferData(values);
    }

    const handleReset = () => {
        setSearchValues({
            businessName: '', category: '', postcode: '', otherCategory : ''
        });
        getBusinessIReferData({});
    };

    return (
        <div>
            <Header pagTitle="ReferUs Network" isMenuShow={false} />
            <div style={{ padding: '0 32px'}}>
                {/* <PageTitle pagTitle='' /> */}
            </div>
            <Row className="contentBody" style={{ justifyContent: 'center', marginBottom: '0px' }}>
                <CustomCard />
            </Row>
            <Row style={{textAlign: 'center', display: 'contents'}}>
                <div className='card-below-title'>Become a Referrer for any of the businesses and get rewarded!</div>
            </Row>
            <div className="contentBody" style={{ marginTop: '3em', marginBottom: '2em'}}>
                <div className="bodycard">
                    <div className=''>
                        <Row gutter={[16, 16]} style={{ marginBottom: '16px', width: '100%' }}>
                            {/* Business Name Input */}
                            <Col span={6} sm={24} lg={6} xs={24}>
                                <Input
                                    placeholder="Enter business name"
                                    value={searchValues.businessName}
                                    style={{ height: '50px', marginBottom: '16px' }}
                                    onChange={(e) => setSearchValues({ ...searchValues, businessName: e?.target?.value })}
                                />
                            </Col>

                            {/* Business Category Select */}
                            <Col span={6} sm={24} lg={6} xs={24}>
                                <Select
                                    value={searchValues?.category || undefined} // Ensure this is undefined when no selection
                                    allowClear // Enable clear option
                                    style={{ width: '100%', height: '50px', marginBottom: '16px' }}
                                    onChange={(e) => setSearchValues({ ...searchValues, category: e })}
                                    placeholder="Business Category"
                                >
                                    {businessCategories?.map((item, index) => (
                                        <Select.Option key={index} value={item?.value}>
                                            {item.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                            {/* Conditionally render the Other Category input */}
                            {searchValues.category === 'other' && (
                                <Col span={6} sm={24} lg={6} xs={24}>
                                <Input
                                    placeholder="Enter other business category"
                                    value={searchValues.otherCategory}
                                    style={{ height: '50px', marginBottom: '16px' }}
                                    onChange={(e) => setSearchValues({ ...searchValues, otherCategory: e.target.value })}
                                />
                                </Col>
                            )}

                            {/* Postcode Input */}
                            <Col span={6} sm={24} lg={6} xs={24}>
                                <Input
                                    placeholder="PostCode"
                                    value={searchValues.postcode}
                                    style={{ height: '50px', marginBottom: '16px' }}
                                    onChange={(e) => setSearchValues({ ...searchValues, postcode: e?.target?.value })}
                                />
                            </Col>

                            {/* Search Button */}
                            <Col span={3} sm={12} xs={12} lg={3}>
                                <Button
                                    type="primary"
                                    className='btn btn-primary btn-filter'
                                    onClick={handleSearch}
                                >
                                    Search
                                </Button>
                            </Col>
                            <Col span={3} sm={12} xs={12} lg={3}>
                                <Button
                                    // size='large'
                                    onClick={handleReset}
                                    className='btn-filter reset'
                                >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="cardContentbody">
                     {isLoading ? <Spin style={{padding: '25px', textAlign: 'center', display: 'block'}}></Spin> : tableComponent}
                    </div>
                    <div>
                        <CardPagination activePage={page} total={pagination?.totalItems || 0} handleChange={handleTableChange} />
                    </div>
                </div>
            </div>
                {/* Footer */}
                <div className="footerbusinessLanding">
                    <div className="text-center">
                        <div className="footerbusinesstext">
                            © {new Date().getFullYear()} ReferUs , All rights
                            reserved
                        </div>
                    </div>
                </div>
            <Modal
                open={showConfirm}
                title="Are you sure you want to become referrer?"
                onOk={() => setShowConfirm(!showConfirm)}
                onCancel={() =>setShowConfirm(!showConfirm)}
                footer={[
                    <Button key="back" className='btn btn-outline-primary-md' onClick={()=> setShowConfirm(!showConfirm)}>
                        Cancel
                    </Button>,
                    <Button key="submit" className='btn btn-primary-md' type="primary" loading={isLoading} onClick={() => setShowConfirm(!showConfirm)}>
                        Ok
                    </Button>
                ]}
            >
                Once you click Ok it won't be reverted.
            </Modal>
        </div>
    );
};

export default ReferUsNetwork;
