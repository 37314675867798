import ApiClient from '../api-client/apiClient';
// @ts-ignore
import { PORT, apiUrl, version } from '../environment/environment';


import { notification } from 'antd';
import { registerSuccess } from '@store/Auth/user';
import { handleIsLoading, handleLoading } from '@store/general';
type NotificationType = 'success' | 'info' | 'warning' | 'error';

function openNotificationWithIcon(type: NotificationType, message: string) {
    notification[type]({
        message: message,
        placement: 'topRight', // You can change the placement if needed
    });
}

export const login = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch({ type: 'ISLOADING', data: true });
        ApiClient.post(`${apiUrl}${PORT}/auth/login`, data).then(
            (response: any) => {
                dispatch({ type: 'ISLOADING', data: false });
                if (response.messageID === 200) {
                    dispatch({
                        type: 'DETAILS_GROUP',
                        data: response.data.group,
                    });
                    dispatch({ type: 'LOGIN', data: response.data });
                    localStorage.setItem('LOGOUT', JSON.stringify(false));
                    // dispatch(getLangauge());
                    // toast.success(response.message);
                    return callback(response);
                } else if (response.messageID === 404) {
                    // toast.warn(response.message);
                    return callback(response);
                } else {
                    // toast.error(response.message);
                }
            }
        );
    };
};

export const checkuser = (callback: any) => {
    return (dispatch: any, getState: any) => {
        const {
            user: { token },
        } = getState();
        dispatch({ type: 'ISLOADING', data: true });
        ApiClient.get(
            `${apiUrl}${PORT}/auth/checkuser`,
            {},
            token,
            dispatch
        ).then((response: any) => {
            dispatch({ type: 'ISLOADING', data: false });
            if (response.messageID === 200) {
                dispatch({ type: 'DETAILS_GROUP', data: response.data.group });
                dispatch({ type: 'LOGIN', data: response.data });
                // dispatch(getLangauge());
                callback(response);
            } else if (response.messageID === 404) {
                // toast.warn(response.message);
            } else {
                // toast.error(response.message);
            }
        });
    };
};

export const user = (params: any) => {
    return (dispatch: any, getState: any) => {
        const {
            user: { token },
        } = getState();
        dispatch({ type: 'ISLOADING', data: true });
        ApiClient.get(`${apiUrl}${PORT}/users`, params, token, dispatch).then(
            (response: any) => {
                dispatch({ type: 'ISLOADING', data: false });

                if (response.messageID === 200) {
                    dispatch({ type: 'LIST_USERS', data: response.data });
                } else if (response.messageID === 404) {
                    // toast.warn(response.message);
                } else {
                    // toast.error(response.message);
                }
            }
        );
    };
};

// *********** bussiness registration **********************
export const userRegister = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch(handleIsLoading(true));
        ApiClient.post(`${apiUrl}${PORT}${version}/admin/business-register`, data).then(
            (response: any) => {
                // dispatch({ type: 'ISLOADING', data: false });
                if (response.status === 200 || response.status === 201) {
                    // openNotificationWithIcon('success', response.message);
                    dispatch(registerSuccess(response.data));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else if (response.status === 404 || response.status === 400) {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                }
            }
        );
    };
};

// *********** bussiness registration buffer data update **********************
export const addBusinessBuffer = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch(handleIsLoading(true));
        ApiClient.post(`${apiUrl}${PORT}${version}/admin/temp-business`, data).then(
            (response: any) => {
                // dispatch({ type: 'ISLOADING', data: false });
                if (response.status === 200 || response.status === 201) {
                    // openNotificationWithIcon('success', response.message);
                    dispatch(registerSuccess(response.data));
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else if (response.status === 404) {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                    return callback(response);
                } else {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleIsLoading(false));
                }
            }
        );
    };
};
export const updateBusinessBuffer = (data: any, callback: any) => {
    return (dispatch: any) => {
        try{

            dispatch(handleIsLoading(true));
            ApiClient.post(`${apiUrl}${PORT}${version}/payment/update`, data).then(
                (response: any) => {
                    if (response.status === 200 || response.status === 201) {
                        // openNotificationWithIcon('success', response.message);
                        dispatch(registerSuccess(response.data));
                        dispatch(handleIsLoading(false));
                        return callback(response);
                    } else if (response.status === 404) {
                        openNotificationWithIcon('error', response.message);
                        dispatch(handleIsLoading(false));
                        return callback(response);
                    } else {
                        openNotificationWithIcon('error', response.message);
                        dispatch(handleIsLoading(false));
                    }
                }
            );
        } catch(err) {
            if(err) {
                dispatch(handleIsLoading(false));
            }
        }
    };
};

// *************** Get referrer details from id ************************** //
export const getReferrerDetailsAction = (data: any, callback: any) => {
    return (dispatch: any) => {
        try{

            dispatch(handleIsLoading(true));
            ApiClient.post(`${apiUrl}${PORT}${version}/admin/get-referrer-by-id/${data?.id}`, data).then(
                (response: any) => {
                    if (response.status === 200 || response.status === 201) {
                        // openNotificationWithIcon('success', response.message);
                        // dispatch(registerSuccess(response.data));
                        dispatch(handleIsLoading(false));
                        return callback(response);
                    } else if (response.status === 404) {
                        openNotificationWithIcon('error', response.message);
                        dispatch(handleIsLoading(false));
                        return callback(response);
                    } else {
                        openNotificationWithIcon('error', response.message);
                        dispatch(handleIsLoading(false));
                    }
                }
            );
        } catch(err) {
            if(err) {
                dispatch(handleIsLoading(false));
            }
        }
    };
};


// *********** bussiness registration **********************
export const contactUs = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch(handleLoading(true));
        ApiClient.post(`${apiUrl}${PORT}${version}/contactUs/addContactUs`, data).then(
            (response: any) => {
                // dispatch({ type: 'ISLOADING', data: false });
                if (response.status === 200 || response.status === 201) {
                    openNotificationWithIcon('success', response.message);
                    dispatch(registerSuccess(response.data));
                    dispatch(handleLoading(false));
                    return callback(response);
                } else if (response.status === 404) {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleLoading(false));
                    return callback(response);
                } else {
                    openNotificationWithIcon('error', response.message);
                    dispatch(handleLoading(false));
                }
            }
        );
    };
};

export const forgot_password = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch({ type: 'ISLOADING', data: true });
        ApiClient.get(`${apiUrl}${PORT}/auth/forgot-password`, data).then(
            (response: any) => {
                dispatch({ type: 'ISLOADING', data: false });

                if (response.messageID === 200) {
                    // toast.success(response.message);
                    dispatch({ type: 'FORGOTPASSWORD', data });
                    return callback(response);
                } else if (response.messageID === 404) {
                    // toast.warn(response.message);
                    return callback(response);
                } else {
                    // toast.error(response.message);
                }
            }
        );
    };
};

export const check_password_token = (data: any, callback: any) => {
    return (dispatch: any) => {
        dispatch({ type: 'ISLOADING', data: true });
        ApiClient.get(
            `${apiUrl}${PORT}/auth/check-password-token/${data.token}`,
            dispatch
        ).then((response: any) => {
            dispatch({ type: 'ISLOADING', data: false });

            if (response.messageID === 200) {
                // toast.success(response.message);
                dispatch({ type: 'PASSWORDTOKEN', data: response.data });
                // return callback(response);
            } else if (response.messageID === 404) {
                // toast.warn(response.message);
                // return callback(response);
            } else {
                // toast.error(response.message);
            }
        });
    };
};

export const logout = (data: any, callback: any) => {
    return (dispatch: any, getState: any) => {
        dispatch({ type: 'ISLOADING', data: true });
        const {
            user: { token },
        } = getState();
        localStorage.setItem('LOGOUT', JSON.stringify(true));
        ApiClient.get(
            `${apiUrl}${PORT}/auth/logout`,
            data,
            token,
            dispatch
        ).then((response: any) => {
            dispatch({ type: 'ISLOADING', data: false });

            if (response.messageID === 200) {
                // toast.success(response.message);
                dispatch({ type: 'LOGOUT', data: {} });
                dispatch({ type: 'DETAILS_GROUP', data: {} });
                return callback(response);
            } else if (response.messageID === 404) {
                return callback(response);
            } else {
                dispatch({ type: 'LOGOUT', data: {} });
                // toast.error(response.message);
            }
        });
    };
};

export const loader = (flag: any) => {
    return (dispatch: any, getState: any) => {
        dispatch({ type: 'ISLOADING', data: flag });
    };
};

export const set_active_tab = (tab: any) => {
    return (dispatch: any, getState: any) => {
        dispatch({ type: 'SET_ACTIVE_TAB', data: tab });
    };
};
