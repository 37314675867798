export function formatAndCapitalizeString(str: string) {
    return str && str?.replace(/-/g, ' ')?.replace(/\b\w/g, match => match?.toUpperCase());
  }

  export function extractVideoId(url: string) {
    const urlRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(urlRegex);
    return (match && match[7]?.length === 11) ? match[7] : false;
  }

  export function getTokenFromUrl(url:string) {
    const urlParts = url?.split('/');
    return urlParts[urlParts?.length - 1];
}


export const createUniqueRandomStringGenerator = () => {
  const generatedStrings = new Set();

  const generateUniqueString = () => {
      const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let uniqueString;

      do {
          uniqueString = Array.from({ length: 8 }, () =>
              characters[Math.floor(Math.random() * characters.length)]
          ).join('');
      } while (generatedStrings.has(uniqueString));

      generatedStrings.add(uniqueString);
      return uniqueString;
  };

  return generateUniqueString();
};

let key:any = 9876543210
// Function to encrypt a number
export function encryptNumber(number:any, nKey = key) {
  return number ^ nKey;  // XOR the number with the key
}

// Function to decrypt the number
export function decryptNumber(encryptedNumber:any, nKey = key) {
  return encryptedNumber ^ nKey;  // XOR the encrypted number with the key
}

export function capitalizeFirstLetter(str:string) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export function capitalizeAfterComma(str: string) {
  return str?.split(',').map((word, index) => {
    if (index > 0) {
      return word?.trim()?.charAt(0)?.toUpperCase() + word?.slice(1);
    } else {
      return word?.trim();
    }
  }).join(', ');
}

export const commaSeparatedString =(dataArray: any) => dataArray?.map((item:any) => item.value?.trim() ? item.value : "").join(', ');

export function truncateText(text: string, limit = 10) {
  if (text?.length <= limit) {
    return text;
  }

  // Find the last space within the limit
  const truncated = text?.substring(0, limit);
  const lastSpaceIndex = truncated?.lastIndexOf(' ');

  // If a space is found, truncate at the last space, else truncate at the limit
  return lastSpaceIndex > 0 ? text?.substring(0, lastSpaceIndex) + '...' : text?.substring(0, limit) + '...';
}

export function appendRegisterReferrer(url:string) {
  // Create a new URL object
  const parsedUrl = new URL(url);

  // Split the pathname into segments
  const segments = parsedUrl.pathname.split('/');

  // Find the index of 'business-details'
  const index = segments.indexOf('business-details');

  // If 'business-details' is found, insert '/register-referrer' after it
  if (index !== -1) {
      segments.splice(index + 1, 0, 'register-referrer');
  }

  // Reconstruct the pathname
  parsedUrl.pathname = segments.join('/');

  // Return the modified URL as a string
  return parsedUrl.toString();
}


export const getQueryValue =(str:string)=> {
  // Get the query string from the current URL
  const queryString = window.location.search;

  // Create a URLSearchParams object
  const urlParams = new URLSearchParams(queryString);

  // Get the 'id' parameter value
  const idValue = urlParams.get(str);
  return idValue;
}