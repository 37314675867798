import React from "react";
import { Icons } from "./Icons";

export const SocialMediaIcons = (data: any) => {
    return (
        <div className="socialmedIcons">
            {data?.data.map((item: any) => {
                const icon = Icons[item?.platform];
                const url = item?.url.startsWith("http")
                    ? item?.url
                    : `https://${item?.url}`;
                    console.log(icon,"......sdgfdg....");
                    

                return (
                    icon && (
                        <a
                            key={item?.id}
                            className="socialIcons"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {icon}
                        </a>
                    )
                );
            })}
        </div>
    );
};

export default SocialMediaIcons;
