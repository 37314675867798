// just a blank webmap
export const WEB_MAP_ID = '5f3b7605b3364e7bb2416c93fae00995';

export const socialMediaList = [
    {
        value: "facebook",
        label: "Facebook"
    },
    {
        value: "instagram",
        label: "Instagram"
    },
    {
        value: "linkedIn",
        label: "LinkedIn"
    },
]

export const businessCategories = [
    { "value": "accounting-and-bookkeeping", "label": "Accounting and bookkeeping" },
    { "value": "appliance-repair", "label": "Appliance repair" },
    { "value": "bakeries", "label": "Bakeries" },
    { "value": "beauticians", "label": "Beauticians" },
    { "value": "beauty-salons", "label": "Beauty salons" },
    { "value": "beauty-therapy", "label": "Beauty Therapy" },
    { "value": "bicycle-repair-shops", "label": "Bicycle repair shops" },
    { "value": "bookstores", "label": "Bookstores" },
    { "value": "builders", "label": "Builders" },
    { "value": "business-services", "label": "Business Services" },
    { "value": "car-dealerships", "label": "Car Dealerships" },
    { "value": "catering-services", "label": "Catering services" },
    { "value": "chiropractors-or-acupuncturists", "label": "Chiropractors or acupuncturists" },
    { "value": "clothing-stores", "label": "Clothing stores" },
    { "value": "coffee-shops-or-cafes", "label": "Coffee shops or cafés" },
    { "value": "counseling-or-therapy-practices", "label": "Counseling or therapy practices" },
    { "value": "craft-or-diy-workshops", "label": "Craft or DIY workshops" },
    { "value": "creative-and-arts", "label": "Creative and Arts" },
    { "value": "custom-furniture-or-carpentry", "label": "Custom furniture or carpentry" },
    { "value": "cybersecurity-consulting", "label": "Cybersecurity consulting" },
    { "value": "dance-or-art-studios", "label": "Dance or art studios" },
    { "value": "daycares-or-preschools", "label": "Daycares or preschools" },
    { "value": "day-spa", "label": "Day Spa" },
    { "value": "drone-services", "label": "Drone services (e.g., photography, surveying)" },
    { "value": "education-and-tutoring", "label": "Education and Tutoring" },
    { "value": "electrician", "label": "Electrician" },
    { "value": "entertainment-and-leisure", "label": "Entertainment and Leisure" },
    { "value": "entertainment-centers", "label": "Entertainment centers" },
    { "value": "event-planning-or-coordination", "label": "Event planning or coordination" },
    { "value": "fashion-retail", "label": "Fashion Retail" },
    { "value": "food-trucks", "label": "Food trucks" },
    { "value": "graphic-design-or-branding-studios", "label": "Graphic design or branding studios" },
    { "value": "gym-fitness-center", "label": "Gym/ Fitness Center" },
    { "value": "hairdressers", "label": "Hairdressers" },
    { "value": "handyman-services", "label": "Handyman services" },
    { "value": "home-and-maintenance", "label": "Home and Maintenance" },
    { "value": "house-cleaning-services", "label": "House cleaning services" },
    { "value": "interior-design-or-staging", "label": "Interior design or staging" },
    { "value": "it-hardware-repair", "label": "IT hardware repair (e.g., computers, phones)" },
    { "value": "it-support-or-tech-consulting", "label": "IT support or tech consulting" },
    { "value": "juice-and-smoothie-bars", "label": "Juice and smoothie bars" },
    { "value": "landscaping-and-lawn-care", "label": "Landscaping and lawn care" },
    { "value": "legal-services", "label": "Legal services (e.g., paralegals, notaries)" },
    { "value": "marketing-or-social-media-consulting", "label": "Marketing or social media consulting" },
    { "value": "massage-therapy-practices", "label": "Massage therapy practices" },
    { "value": "moving-companies", "label": "Moving companies" },
    { "value": "nail-salons", "label": "Nail salons" },
    { "value": "nutrition-consulting", "label": "Nutrition consulting" },
    { "value": "online-retail-stores", "label": "Online retail stores" },
    { "value": "painting-and-home-improvement", "label": "Painting and home improvement" },
    { "value": "personal-trainers-or-fitness-studios", "label": "Personal trainers or fitness studios" },
    { "value": "pet-grooming-services", "label": "Pet grooming services" },
    { "value": "photography-or-videography", "label": "Photography or videography" },
    { "value": "plumber", "label": "Plumber" },
    { "value": "restaurants", "label": "Restaurants" },
    { "value": "retail", "label": "Retail" },
    { "value": "rideshare-or-delivery-services", "label": "Rideshare or delivery services" },
    { "value": "solar-panel-installation", "label": "Solar panel installation" },
    { "value": "specialty-food-shops", "label": "Specialty food shops" },
    { "value": "technology", "label": "Technology" },
    { "value": "trade", "label": "Trade" },
    { "value": "transportation", "label": "Transportation" },
    { "value": "truck-dealerships", "label": "Truck Dealerships" },
    { "value": "vehicle-detailing-services", "label": "Vehicle detailing services" },
    { "value": "web-or-app-development", "label": "Web or app development" },
    { "value": "wellness-coaching", "label": "Wellness coaching" },
    { "value": "wholesaler", "label": "Wholesaler" },
    { "value": "yoga-or-pilates-studios", "label": "Yoga or Pilates studios" }
]
// [
//     { value: "accommodation-and-hotels", label: "Accommodation and hotels" },
//     { value: "agriculture", label: "Agriculture" },
//     { value: "arts-culture-entertainment", label: "Arts Culture, entertainment" },
//     { value: "construction", label: "Construction" },
//     { value: "education-and-training", label: "Education and training" },
//     { value: "fashion", label: "Fashion" },
//     { value: "financial-and-insurance", label: "Financial and insurance" },
//     { value: "fitness-and-sport", label: "Fitness and sport" },
//     { value: "gardening-and-lawnmowing", label: "Gardening and Lawnmowing" },
//     { value: "hairdressing", label: "Hairdressing" },
//     { value: "healthcare", label: "Healthcare" },
//     { value: "landscaping", label: "Landscaping" },
//     { value: "marketing-and-advertising", label: "Marketing and Advertising" },
//     { value: "mining", label: "Mining" },
//     { value: "ndis", label: "NDIS" },
//     { value: "plumbing", label: "Plumbing" },
//     { value: "retail-trade", label: "Retail Trade" },
//     { value: "security", label: "Security" },
//     { value: "tradie", label: "Tradie" },
//     { value: "wholesale-trade", label: "Wholesale trade" },
//     { value: "other", label: "other" }
// ];

export const supportEmailId = "info@ReferUsNetwork.com";