import { Link, useNavigate } from 'react-router-dom';
import DropdownMessage from './DropdownMessage';
import DropdownNotification from './DropdownNotification';
import DropdownUser from './DropdownUser';
import LogoIcon from '../../../images/logo/refer-refer-us.png';
import { Avatar, Dropdown } from "antd";
// import DarkModeSwitcher from './DarkModeSwitcher';
import React from 'react';
import { LeftCircleOutlined } from '@ant-design/icons';

interface HeaderProps {
    pagTitle: string;
    isMenuShow?: boolean;
}

const Header: React.FC<HeaderProps> = ({ pagTitle, isMenuShow=true }) => {
    const navigate = useNavigate()
    return (
        <header className="mainHeader">
            <div className="pagetitles">
                {!isMenuShow && <LeftCircleOutlined style={{marginRight: '10px'}} onClick={()=> navigate(-1)} />}
                {pagTitle}
            </div>
            {isMenuShow ? <div className="secondmenu">
                {/* <Avatar style={{ backgroundColor: '#87d068' }}  /> */}
                <DropdownNotification/>
                <DropdownUser/>
            </div> : ""}
        </header>
    );
};

export default Header;
