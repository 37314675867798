// @ts-ignore
import { stagingCentPaymentUrl, monthlyPaymentUrl, yearlyPaymentUrl } from '../../environment/environment';
import React, { useEffect, useRef, useState } from 'react';
import RegisterFrom from './RegisterFrom';
import { PaymentForm } from './PaymentForm';
import { useDispatch } from 'react-redux';
import { addBusinessBuffer, getReferrerDetailsAction, updateBusinessBuffer } from '../../actions/user';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { checkBusinessAvailabilityAction } from '../../actions/business';
// import MoonclerkPaymentForm from './MoonclerkPaymentForm';
// import MoonclerkEmbed from './MoonclerkEmbed';
import { encryptNumber, getQueryValue, getTokenFromUrl } from '@utils/common/common';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import './index.css'
// import { Button } from 'antd';


// Declare the global type for MoonclerkEmbed
declare global {
    interface Window {
      MoonclerkEmbed: any; // Use 'any' if the exact type is unknown
    }
  }
  
  interface MoonclerkProps {
    checkoutToken?: string;
    width?: string;
    amountCents?: number;
    name?: string;
    email?: string;
    cid?: string;
  }

  interface PaymentDetails {
    // Define the properties of paymentDetails if applicable
    // For example:
    // paymentMethod: string;
    // paymentFrequency: string;
  }
  
  interface PayloadType {
    companyName?: string;
    contactName?: string;
    abn?: string;
    position?: string;
    email?: string;
    mobileNumber?: string;
    contactNumber?: string;
    address?: string;
    businessCategory?: string;
    socialMediaUrl?: string;
    yearly: boolean;
    monthly: boolean;
    otherPlan: boolean;
    paymentDetails: PaymentDetails;
    termsAndConditions?: boolean;
    isReferUs?: boolean;
    referrerCode?: string;
    isSubscription?: boolean;
  }

const Register = () => {
    const [step, setStep] = useState(1)
    const { isLoading } = useSelector((state: any) => state?.General);
    let userData = localStorage.getItem('stepOneBussiness') && JSON.parse(localStorage.getItem('stepOneBussiness'));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { businessCode } = useParams();    
    const [planValue, setPlanValue] = useState('');
    const paymentLinkRef = useRef<any>(null); // Create a ref for the payment link

    const handleNext = async (values: any) => {
        const payload:PayloadType = {
            companyName: values?.companyName,
            contactName: values?.contactName,
            abn: values?.abn,
            position: values?.position,
            email: values?.email,
            mobileNumber: values?.mobileNumber,
            contactNumber: values?.contactNumber,
            address: values?.address,
            businessCategory: values?.businessCategory,
            // socialMedia: values?.socialMedia,
            socialMediaUrl: values?.socialMediaUrl,
            yearly: false,
            monthly: false,
            // isSubscription: false,
            otherPlan: false,
            paymentDetails: {},
            termsAndConditions: values?.termsAndConditions,
        };
        if(userData?.email === values?.email) {
            setStep(2)
        } else {
            await dispatch(
                checkBusinessAvailabilityAction(
                    { companyName: values?.companyName, email: values?.email },
                    (resp: any) => {
                        if (resp.status === 200){
                            localStorage.setItem('stepOneBussiness', JSON.stringify(payload));
                            dispatch(
                                addBusinessBuffer(payload, (resp: any) => {
                                    if (resp) {
                                        // let id = encryptNumber(resp?.data?.temp?.id)
                                        localStorage.setItem('userId', JSON.stringify(resp?.data?.temp?.id));
                                        // localStorage.setItem('businessResponse', JSON.stringify(resp?.data?.temp));
                                    }
                                })
                            );
                            setStep(2);
                        } 
                    }
                )
            );
        }

    };
    let paymentLink:any = '' // monthlyPaymentUrl;
    let token:any = '' // getTokenFromUrl(monthlyPaymentUrl);
    let userIdStored = (!localStorage.getItem('userId') || localStorage.getItem('userId') !== 'undefined') && encryptNumber(JSON.parse(localStorage.getItem('userId')))

    switch(planValue) {
        case 'test' :{
            paymentLink = stagingCentPaymentUrl
            token = getTokenFromUrl(stagingCentPaymentUrl)
            // setIsUserUpdated(true);
            break;
        }
        case 'monthly' :{
            paymentLink = monthlyPaymentUrl
            token = getTokenFromUrl(monthlyPaymentUrl)
            // setIsUserUpdated(true);
            break;
        }
        case 'yearly' :{
            paymentLink = yearlyPaymentUrl
            token = getTokenFromUrl(yearlyPaymentUrl)
            // setIsUserUpdated(true);
            break;
        } 
        default: {
            paymentLink = '' //monthlyPaymentUrl
            token = '' //getTokenFromUrl(monthlyPaymentUrl)
            // setIsUserUpdated(true);
            break;
        }
    }

    const handlePayment = async (value: any) => {
        // Set the payment URL based on the planValue
        let payloadData = {
            ...userData,
            ...value,
            isSubscription: false,
        }
        if(businessCode !== undefined) {
            payloadData.isReferUs = true;
            payloadData.referUsCode = businessCode
        }
        try {
            await dispatch(
                updateBusinessBuffer(payloadData, (resp: any) => {
                    if(resp?.status === 403 || resp?.status === 500) {
                        localStorage.removeItem('businessDetails')
                        localStorage.removeItem('userId');
                        setStep(1)
                    } else if (resp?.status === 201 || resp?.status === 200) {
                        localStorage.setItem('businessDetails', JSON.stringify(payloadData))
                        localStorage.setItem('userId', JSON.stringify(resp?.data?.id));
                    }
                })
            );
        } catch(err) {
            console.log('Error ::::::::::', err)
            if(err) {
                localStorage.removeItem('businessDetails')
                localStorage.removeItem('userId');
                setStep(1)
            }
        }
    };


    useEffect(() => {
        if (!token) return;
    
        const script = document.createElement('script');
        script.src = 'https://d2l7e0y6ygya2s.cloudfront.net/assets/embed.js';
        script.async = true;
    
        script.onload = () => {
            try {
                if (window.MoonclerkEmbed) {
                    const embedElement = document.getElementById(`mc${token}`);
                    if (embedElement) {
                        const opts = {
                            checkoutToken: token,
                            width: '100%',
                            amount_cents: "", // Add your amount logic here
                            cid: userIdStored || '1286608619',
                        };
    
                        // Initialize and display Moonclerk Embed
                        const mcEmbed = new window.MoonclerkEmbed(opts);
                        mcEmbed.display();
                    } else {
                        console.error("Target element for MoonclerkEmbed not found.");
                    }
                } else {
                    console.error("MoonclerkEmbed is not available on the window object.");
                }
            } catch (error) {
                console.error('Error initializing MoonclerkEmbed:', error);
            }
        };
    
        script.onerror = (error) => {
            console.error('Failed to load the Moonclerk script:', error);
        };
    
        document.body.appendChild(script);
    
        return () => {
            document.body.removeChild(script);
        };
    }, [token, userIdStored]); // Include token and userIdStored as dependencies

    return (
        <div>
            <div className='authforms'>
                <div className="authHeadings">
                    <button 
                    onClick={()=> {
                        if(step == 2) {
                            setStep(step-1)
                        } else {
                            navigate(-1)
                        }
                    }} className='btn textAuthHeading'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect x="0.4" y="0.4" width="23.2" height="23.2" rx="11.6" stroke="#F47523" stroke-width="0.8" />
                        <path d="M14 8L10 12L14 16" stroke="#F47523" stroke-width="1.25" stroke-linecap="round" />
                    </svg> </button>
                    {step === 1 
                        ? <div className="textAuthHeading">Sign <span>Up</span></div>
                        : <div className="textAuthHeading">Payment</div>
                    }
                </div>
                <div className="paginationAuth">
                    <div className="pageControlPagination">
                        <div className="pagelist">
                            <div onClick={()=> {
                                if(userData) {
                                    setStep(1)
                                }
                            }} className={`page ${step === 1 ? '' : "notactive"}`}>1</div>
                            <div onClick={()=> {
                                if(userData) {
                                    setStep(2)
                                }
                            }} className={`page ${step === 2 ? '' : "notactive"}`}>2</div>
                        </div>
                    </div>
                </div>

                {step == 1 ? <RegisterFrom userData={userData} handleSubmit={(value: any) => handleNext(value)} /> : ""}
                {step == 2 ? <PaymentForm ref={paymentLinkRef} paymentUrl={paymentLink} userId={userIdStored} handlePayment={(value: any) => { handlePayment(value) }} handlePlanValueChange={(value: any) => setPlanValue(value)} /> : ""}
                {(step === 2 && token) ? (
                    <div id={`mc${token}`}>
                        {/* The Moonclerk embed will be inserted here */}
                        <a
                            ref={paymentLinkRef}
                            href={`https://app.moonclerk.com/pay/${token}?cid=${userIdStored || '1286608619'}`}
                        >
                            {/* Optional: Payment button or other elements */}
                        </a>
                    </div>
                ) : ""}
            </div>
        </div>
    );
};

export default Register;
