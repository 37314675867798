import React from 'react';
import mainLogo from '../../images/logo/darkLogo.png';
import networkIcon from '../../images/icon/ReferUS Network.png';

const CustomCard = () => {
  return (
    <>
      <div className="top-card">
        <div className="image-container">
          <div className="image-left">
            <img src={mainLogo} alt="Image 1" />
          </div>
          <div className="image-right">
            <img src={networkIcon} alt="Image 2" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomCard;