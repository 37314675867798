import React from 'react';
import { Pagination } from 'antd';

const CardPagination = ({ activePage, total, handleChange }: any) => {
    return (
        <div className='custom-pagination'>
            <Pagination defaultCurrent={activePage} total={total} onChange={handleChange} />
        </div>
    )
};

export default CardPagination;