import { Button, message, Result, Spin } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
// @ts-ignore
import { adminWebUrl } from '../../environment/environment';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userRegister } from '../../actions/user';
import { decryptNumber } from '@utils/common/common';

const PaymentSuccessful = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const customId = queryParams.get('custom_id');
  let stringUserId = localStorage.getItem('userId');
  let stringBusinessData = localStorage.getItem('businessDetails');
  let businessData = stringBusinessData && JSON.parse(stringBusinessData);
  let storedID = stringUserId && JSON.parse(stringUserId);
  let originId = storedID?.toString();
  const dispatch = useDispatch();
  const [status, setStatus] = useState<any>('error')

  // Ref to track if the effect has already run
  const hasRun = useRef(false);

  const makeRegisterWithPayment = async () => {
    setIsLoading(true);
    let payloadData = {
      ...businessData,
      custom_id: customId,
      paymentId: customId,
      isSubscription: true
    }
    if (payloadData?.email) {
      setTimeout(() => {
        setStatus('success')
        localStorage.removeItem('userId');
        localStorage.removeItem('businessResponse');
        localStorage.removeItem('businessDetails');
        localStorage.removeItem('stepOneBussiness');
        setIsLoading(false);
      }, 5000)
    } else {
      setTimeout(() => {
        setIsLoading(false);
        setStatus('error')
      }, 1000)
    }
  }

  useEffect(() => {
    // Prevent the effect from running multiple times in development due to React Strict Mode
    if (hasRun.current) return; // If the effect has already run, exit early
    hasRun.current = true; // Mark the effect as run

    if (customId) {
      makeRegisterWithPayment(); // Trigger registration process if condition matches
    }

  }, [storedID, originId]);  // Dependencies are storedID and originId


  function SubscriptionStatus({ status }: any) {
    let message;
    let messageStyle;

    switch (status) {
      case 'success':
        return <>
          {/* Success Message */}
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#4CAF50' }}>
            Subscription Activated Successfully!
          </div>
          <div style={{ fontSize: '16px', color: '#555' }}>
            Thank you for subscribing to ReferUs Network! 🎉
          </div>
          <div style={{ fontSize: '16px', color: '#555' }}>
            Your login credentials have been sent to your email address.
          </div>
          <div style={{ marginTop: '20px', fontSize: '14px', color: '#777' }}>
            Please check your inbox to proceed.
          </div>
        </>;

      case 'info':
        return <>
          {/* Pending Message */}
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#FF9800' }}>
            Your Subscription is Pending
          </div>
          <div style={{ fontSize: '16px', color: '#555' }}>
            Your ReferUs Network Subscription is being processed.
          </div>
          <div style={{ fontSize: '16px', color: '#555' }}>
            Please wait a moment for the confirmation. We'll notify you once it's completed.
          </div>
          <div style={{ marginTop: '20px', fontSize: '14px', color: '#777' }}>
            If you have any concerns, please reach out to our support team.
          </div>
        </>
      case 'error':
        return <>
          {/* Error Message */}
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#F44336' }}>
            Subscription Error
          </div>
          <div style={{ fontSize: '16px', color: '#555' }}>
            There was an issue processing your ReferUs Network Subscription.
          </div>
          <div style={{ fontSize: '16px', color: '#555' }}>
            Please try again or contact our support team for assistance.
          </div>
          <div style={{ marginTop: '20px', fontSize: '14px', color: '#777' }}>
            We apologize for the inconvenience and are here to help.
          </div>
        </>
      default:
        message = "Status unknown.";
        messageStyle = { color: 'gray' };
    }

    return <div style={messageStyle}>{message}</div>;
  }

  return (
    <div style={{ marginTop: '200px' }}>
      {isLoading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          right: 0,
          width: '50%',  // Occupy the right half of the screen
          height: '100%',  // Full height
          backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional: semi-transparent background
          zIndex: 9999,  // Ensure it overlays the content
        }}>
          <Spin tip="Wait...">
            <div className="loading-text">Hang on, we are processing your payment!</div>
          </Spin>
        </div>
      ) : (
        <Result
          status={status}
          title={<SubscriptionStatus status={status} />}
          extra={[
            <Button
              key="login"
              className="btn btn-primary w-100"
              type="primary"
              onClick={() => status ==='success' ? window.open(`${adminWebUrl}/login`, '_self') : navigate(`/contact-us`)}
            >
              {status ==='success' ? 'Login' : 'Contact Us' }
            </Button>
          ]}
        />
      )}
    </div>
  )
}

export default PaymentSuccessful;
