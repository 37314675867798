import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import networklogo from '../../images/business/networklogo.png';
import maps from '../../images/business/maps.svg';
// import qrcode from '../../images/business/qrcode.svg';
import referUsIcon from '../../images/icon/referusicon.svg';
// import sampleLogo from '../../images/business/samplelogo.jpg';
import { useDispatch } from 'react-redux';
import { businessDetailsAction } from '../../actions/business';
import { Divider, QRCode, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
// import leftImg1 from  '../../images/business/logo4.jpg';
import rightImg1 from  '../../images/business/logobg.png';
import { extractVideoId, formatAndCapitalizeString } from '@utils/common/common';
import VideoPlayer from '@utils/common/VideoPlayer';
// @ts-ignore
import { markettingWebUrl } from '../../environment/environment';
import './index.css';
import MapComponent from '@components/map/GoogleMap';
import { Helmet } from 'react-helmet-async';
import SocialMediaIcons from './SocileMediaIcon';


export const Businesspage = () => {
    const { businessCode, businessName } = useParams();
    const location = useLocation();
    const host = window.location.origin;
    const isLoading = useSelector((state: any) => state?.General?.loading);
    const [data, setData] = useState<any>(undefined);
    const [rewards, setRewards] = useState<any>([]);
    const [services, setServices] = useState<any>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const referrerCode = query.get('referrerCode');
    let registerReferrerUrl = '';

    useEffect(() => {
        console.log('Business Code', businessCode);
        console.log('referrerCode', referrerCode);
        const payload = { businessCode };
        dispatch(
            businessDetailsAction(payload, (resp: any) => {
                console.warn('resp :::::::::::::', resp);
                if (resp?.status === 200) {
                    setData(resp?.data);
                    setRewards(resp?.data?.rewards);
                    setServices(
                        resp?.data?.BusinessDetails?.services
                            ? resp?.data?.BusinessDetails?.services.split(',')
                            : []
                    );
                }
            })
        );
    }, [businessCode]);
    if (referrerCode) {
        registerReferrerUrl = `${host}/business-details/register-referrer/${businessName}/${businessCode}?referrerCode=${referrerCode.toUpperCase()}`;
    } else {
        registerReferrerUrl = `${host}/business-details/register-referrer/${businessName}/${businessCode}`;
    }

    const handleJoinUs = () => {
        if (referrerCode) {
            navigate(
                `/business-details/register-referrer/${businessName}/${businessCode}?referrerCode=${referrerCode.toUpperCase()}`
            );
        } else {
            navigate(
                `/business-details/register-referrer/${businessName}/${businessCode}`
            );
        }
    };

    const handleReferUs =(event: any)=> {
         event.preventDefault();
         window.open(`${markettingWebUrl}/register`, '_blank', 'noopener,noreferrer');
         }

    const handleSocialMedia = (url: string) => {
         window.open(url, "_blank");
         };

    const pageTitle = `${data?.companyName ? data.companyName : 'Referrer'} Marketing Page`;
    let description = `Get more referrals from this page for ${data?.companyName ? data.companyName : 'your business'}`


    return (
        <div className="marketingSite">
            {/* <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={host + location.pathname} />
                <meta property="og:image" content={data?.BusinessDetails?.bannerUrl || '/default-image.jpg'} />
            </Helmet> */}
            <div
                className="coverpage d-flex"
                // style={{
                //     backgroundImage: `url(${data?.BusinessDetails?.bannerUrl})`,
                // }}
            >
                <div className="left-img-col"  style={{ backgroundColor: data?.BusinessDetails?.backgroundColor || 'rgb(255, 244, 237)' }}>
                    <img src={ data?.BusinessDetails?.logoUrl || rightImg1} alt="logo" />
                </div>
                <div className="brand-icon">
                    <p>

                    <img src={referUsIcon} alt="referUsIcon" />
                   Powered by ReferUs Network - <a style={{color: "#F47523"}} href="https://www.referusnetwork.com" target='_blank'>www.ReferUsNetwork.com</a>
                </p>
                    </div>


                {/* <div className="right-img-col">
                <img src={rightImg1} alt="logo" />
                </div> */}
                {/* <div className="coverpage"> */}
                <div className="container">
                    <div className="lowerprofile">
                        <div className="leftContent">
                            {/* <div className="logoholder">
                                <img
                                    src={
                                        data?.BusinessDetails?.logoUrl ||
                                        sampleLogo
                                    }
                                    alt="logo"
                                />
                            </div> */}
                            <div className="textmarketing">
                                <div className="head">{data?.BusinessDetails?.businessName}</div>
                                <div className="subHead">
                                    {formatAndCapitalizeString(data?.businessCategory)}
                                </div>
                            </div>
                        </div>
                        <div className="rightContent">
                            {
                                data?.BusinessDetails?.socialMedia &&
                        <SocialMediaIcons data={data?.BusinessDetails?.socialMedia}/>
                            }
                        </div>
                    </div>
                </div>

                
            </div>
            <div className="contentPage">
                <div className="container">
                    <div className="backcard">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <div className="blockdesign">
                                    <div className="blockheading">
                                        Get To Know About Us
                                    </div>
                                    <div className="blockContent">
                                        {data?.BusinessDetails?.description}
                                        {/* All Opportunities is Indonesia's largest opportunity portal & mentoring platform. We help people easily find opportunities to top Indonesian companies for internship and full-time roles.
                                        As you might have already heard about us, we are revolutionizing how Indonesian engage with employers. Many Indonesian students are talented, ambitious, but never found a better opportunity for themselves. Our team is driven by the vision to democratize information and knowledge, thus opportunities. We think what we build will allow Indonesian students to have a chance to self develop and have a better future */}
                                    </div>
                                </div>
                                {data?.BusinessDetails?.selectedKey ===
                                    'address' && (
                                    <div className="blockdesign">
                                        <div className="blockheading">
                                            Our Address
                                        </div>
                                        <div className="blockContent">
                                            {data?.BusinessDetails?.address}
                                        </div>
                                    </div>
                                )}
                                {data?.BusinessDetails?.contactNumber && (
                                    <div className="blockdesign">
                                        <div className="blockheading">
                                            Our Contact
                                        </div>
                                        <div className="blockContent">
                                            {data?.contactNumber}
                                        </div>
                                    </div>
                                )}
                                <div className="blockdesign">
                                    <div className="blockheading">Services</div>
                                    <div className="blockContent">
                                        <ul>
                                            {services.map(
                                                (service: any, index: any) => (
                                                    <li key={index}>
                                                        {service}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                {
                                    rewards.length > 0 && (
                                <div className="blockdesign">
                                    <div className="blockheading">
                                        Rewards
                                        <div className="blockContent">
                                            <ul className='reward'>
                                                {rewards.map(
                                                    (
                                                        reward: any,
                                                        index: any
                                                    ) => (
                                                        <li
                                                            key={index}
                                                            className={`reward-item ${
                                                                reward.isActive
                                                                    ? 'active'
                                                                    : 'inactive'
                                                            }`}
                                                        >
                                                           <div>
                                                    <strong>
                                                        {reward.rewardName}
                                                        {reward?.referrerRewardValue && ":"}
                                                    </strong>{' '}
                                                    {reward?.referrerRewardType === 'cash' && '$'}
                                                    {reward?.referrerRewardValue}
                                                    {reward?.referrerRewardType === 'percentage' && '%'}
                                                    {' '}
                                                    {
                                                        reward?.description && 
                                                    `(${reward?.description})`
                                                    }
                                                    </div>

                                                            {/* <div>
                                                                <strong>
                                                                    Referrer
                                                                    Reward:
                                                                </strong>{' '}
                                                                {
                                                                    reward.referrerRewardType
                                                                }{' '}
                                                                -{' '}
                                                                {
                                                                    reward.referrerRewardValue
                                                                }
                                                                %
                                                            </div> */}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                    )
                                }
                                <div className="blockdesign">
                                <div className="blockheading">
                                Scan the QR Code below to become a referrer for {data?.BusinessDetails?.businessName} {' '}
                                and receive "rewards" </div>
                                    <div className="blockContent">
                                        <div className='d-flex'>
                                        <div className='join-referrer'>
                                            <div>
                                                <p>Use ReferUs Network for your business. Join Now</p>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={handleJoinUs}
                                                    >
                                                        Become a referrer for us
                                                    </button>
                                                    <p>Select this button to signup as referrer for <i><u>{data?.BusinessDetails?.businessName}{' '}</u></i></p>
                                                </div>
                                                </div>
                                            
                                            <div className='devider'>
                                                <Divider type="vertical" style={{ borderLeft: '2px dashed #F47523', height: '45%', marginTop: '0px' }} />
                                                   <span className='divider-text'>Or</span>
                                                <Divider type="vertical" style={{ borderLeft: '2px dashed #F47523', height: '45%', marginTop: '0px' }} />
                                            </div>
                                            <div className="qrBlock">
                                                {/* <img src={qrcode} alt="qrcode" /> */}
                                                <QRCode
                                                    size={150}
                                                    value={
                                                        registerReferrerUrl || '-'
                                                    }
                                                />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="decoLinks">
                                    {/* <div className="mapsMarketing">
                                            <img src={maps} alt="maps" />
                                        </div> */}
                                    {data?.BusinessDetails?.selectedKey ===
                                        'address' && (
                                        <div className="mapsMarketing">
                                            {/* <img src={maps} alt="maps" /> */}
                                            <MapComponent lat={data?.BusinessDetails?.lat} lng={data?.BusinessDetails?.long} />
                                        </div>
                                    )}

                                    {data?.BusinessDetails?.selectedKey ===
                                        'graphic' && (
                                        <div className="mapsMarketing graphic">
                                            <img
                                                src={
                                                    data?.BusinessDetails
                                                        ?.bannerUrl
                                                }
                                                alt="graphic"
                                            />
                                        </div>
                                    )}

                                    {data?.BusinessDetails?.selectedKey ===
                                        'link' && (
                                            <div className="mapsMarketing graphic">
                                                <VideoPlayer videoId={extractVideoId(data?.BusinessDetails?.link)} />
                                                {/* <iframe
                                                    src={data?.link}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    title="Embedded youtube"
                                                /> */}
                                            </div>
                                        )}

                                    <div className="addverstiseSection">
                                        <img src={networklogo} alt="ReferUs" />
                                        <h4>
                                            Manage your Word-of-Mouth Referrals
                                        </h4>
                                        <p>
                                            Get your happy clients referring you
                                            with ReferUs. When it comes to
                                            marketing your business there's no
                                            one better than a happy customer{' '}
                                        </p>
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleReferUs}
                                        >
                                            Join ReferUs Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerbusiness">
                <div className="text-center">
                    <div className="footerbusinesstext">
                        © {new Date().getFullYear()} ReferUs , All rights
                        reserved
                    </div>
                </div>
            </div>
        </div>
    );
};
